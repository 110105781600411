import axios from "axios";
import { Message } from "element-ui";
import routers from "../router";
var repeat = true;
const service = axios.create({
  baseURL: 'https://api.yudotmc.com',
  // baseURL: "http://192.168.2.138:3121",
  // timeout: 10000, // request timeout
});
service.interceptors.request.use(
  (config) => {
    console.log(config);
    // config.headers.accept =
    // "application/json,text/plain,text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9";
    return config;
  },
  (error) => {
    // do something with request error
    return Promise.reject(error);
  }
);
// service.interceptors?.request.use(
//   function (config) {
//     console.log(config.headers);
//   },
//   function (error) {
//     toast("Network error", "error");
//     // 对请求错误做些什么
//     return Promise.reject(error);
//   }
// );
service.interceptors.response.use(
  (response) => {
    if (response.data.code === 200) {
      if (response.status == "error") {
        Message({
          showClose: true,
          message: response.data.message,
          type: "warning",
          duration: 1000,
        });
        return Promise.reject(new Error(res?.message ?? "Error"));
      }
      const res = response.data;
      return res;
    } else {
      Message({
        showClose: true,
        message: response.data.message,
        type: "warning",
        duration: 1000,
      });
      const res = response.data;
      return Promise.reject(new Error(res?.message ?? "Error"));
    }
  },
  (error) => {
    if (
      error.response.request.status === 403 ||
      error.response.request.status === 401 ||
      error.response.request.status === 407
    ) {
      if (repeat) {
        Message({
          showClose: true,
          message: error.response.data.message,
          type: "warning",
          duration: 1000,
        });
      }
      repeat = false;
      routers.push("/");
    }
    return Promise.reject(error);
  }
);

export default service;
