import router from "../router";
const getToken = () => {
  return localStorage.getItem("token");
};
const setToken = (token) => {
  localStorage.setItem("token", token);
};
const removeToken = () => {
  localStorage.removeItem("token");
};
console.log;
router.beforeEach((to, from, next) => {
  if (!getToken() && to.path != "/") {
    return next({ path: "/" });
  }
  next();
});
