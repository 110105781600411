export function queryParams(query = {}) {
  let q = [];
  for (const key in query) {
    if (query[key] !== undefined && query[key] !== null && query[key] !== "") {
      q.push(`${key}=${encodeURIComponent(query[key])}`);
    }
  }
  let r = q.join("&");
  r = r ? "?" + r : "";
  return r;
}

export function isEmpty(val) {
  if (
    val === null ||
    val === undefined ||
    val === "null" ||
    val === "undefined"
  ) {
    return true;
  }
  if (typeof val === "string" && val.trim() === "") {
    return true;
  }
  if (Array.isArray(val) && val.length === 0) {
    return true;
  }
  if (typeof val === "object" && Object.keys(val).length === 0) {
    return true;
  }
  return false;
}
export function awaitWraper(promise) {
  return promise.then((res) => [null, res]).catch((err) => [err, null]);
}
