import request from "@/js/request";
import { queryParams, isEmpty } from "@/js/util";
// 获取用户信息
export function getUserInfo(data) {
  return request({
    url: "/admin/info",
    method: "get",
    params: data,
  });
}

// 更改个人信息
export function changeInfo(data, param, lang) {
  return request({
    url: "/admin/updateSelf?token=" + param + "&language=" + lang,
    method: "post",
    data: data,
  });
}

// 修改密码
export function changePwd(data, param, lang) {
  return request({
    url: "/admin/updatePwd?token=" + param + "&language=" + lang,
    method: "post",
    data: data,
  });
}

export function getVersionList(params) {
  let newParams;
  if (isEmpty(params)) {
    newParams = queryParams({
      token: localStorage.getItem("token"),
    });
  } else {
    newParams = queryParams({
      ...params,
      token: localStorage.getItem("token"),
    });
  }
  return request.get(`/tmc/version/list${newParams}`);
}

export function addVersion(params) {
  const newParams = queryParams({
    token: localStorage.getItem("token"),
  });
  return request.post(`/tmc/version/add${newParams}`, params);
}
export function deleteVersion(params) {
  const newParams = queryParams({
    ...params,
    token: localStorage.getItem("token"),
  });
  const url = `/tmc/version/del${newParams}`;

  return request({
    url,
    method: "delete",
  });
}

export function uploadFile(params) {
  const newParams = queryParams({
    token: localStorage.getItem("token"),
  });
  const url = `/tmc/version/upload${newParams}`;
  // return request.post(, params);
  return request({
    url,
    method: "post",
    data: params,
    // headers: {
    //   "Content-Type": "multipart/form-data",
    // },
  });
}

export function updateMacAndVersion(params){
  const newParams = queryParams({
    token: localStorage.getItem("token"),
  });
  const url = `/tmc/update${newParams}`;
  return request({
    url,
    method: "put",
    data: params,
  });
}