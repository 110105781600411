var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mainLeft"},[_vm._m(0),_c('div',{staticClass:"left_content"},[_c('div',{staticClass:"left_list",on:{"click":function($event){return _vm.changeSelected(0)}}},[_c('div',{staticClass:"list_color",style:({ background: _vm.isSelected == 0 ? '#4875ef' : '' })}),_c('div',{staticClass:"list_icon ic2"}),_c('div',{staticClass:"list_text",style:({
          color: _vm.isSelected == 0 ? '#fff' : '#d6d9e1',
          fontWeight: _vm.isSelected == 0 ? '700' : '400',
        })},[_vm._v(" "+_vm._s(_vm.$t("Custom.Custom"))+" ")])]),_c('div',{staticClass:"left_list",on:{"click":function($event){return _vm.changeSelected(1)}}},[_c('div',{staticClass:"list_color",style:({ background: _vm.isSelected == 1 ? '#4875ef' : '' })}),_c('div',{staticClass:"list_icon ic1"}),_c('div',{staticClass:"list_text",style:({
          color: _vm.isSelected == 1 ? '#fff' : '#d6d9e1',
          fontWeight: _vm.isSelected == 1 ? '700' : '400',
        })},[_vm._v(" "+_vm._s(_vm.$t("Machine.Equipment"))+" ")])]),_c('div',{staticClass:"left_list",on:{"click":function($event){return _vm.changeSelected(2)}}},[_c('div',{staticClass:"list_color",style:({ background: _vm.isSelected == 2 ? '#4875ef' : '' })}),_c('div',{staticClass:"list_icon ic1"}),_c('div',{staticClass:"list_text",style:({
          color: _vm.isSelected == 2 ? '#fff' : '#d6d9e1',
          fontWeight: _vm.isSelected == 2 ? '700' : '400',
        })},[_vm._v(" "+_vm._s(_vm.$t("Warning.VersionManage"))+" ")])])]),_c('div',{staticClass:"left_bottom"},[_c('div',{staticClass:"layout",on:{"click":_vm.toLogin}}),_c('div',{staticClass:"userName",on:{"click":_vm.userInfo}},[_vm._v(_vm._s(_vm.account))]),_c('div',{staticClass:"time"},[_vm._v(_vm._s(_vm.date)+" "+_vm._s(_vm.time))]),_c('div',{staticClass:"selected"},[_c('el-select',{on:{"change":_vm.changeLan},model:{value:(_vm.companyType),callback:function ($$v) {_vm.companyType=$$v},expression:"companyType"}},_vm._l((_vm.companyTypes),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.value,"value":item.id}})}),1)],1)]),_c('user-info',{ref:"UserInfo"})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"left_Title"},[_c('img',{attrs:{"src":require("../../assets/image/tmcIcon.png")}})])}]

export { render, staticRenderFns }